/**
 * Syntax highlighting styles
 */
 code.highlighter-rouge {
  font-family: Menlo, monospace;
  font-size: 0.875rem;
  white-space: pre-wrap;
 }

pre.highlight {
  background-color: #f8f8f8;
}